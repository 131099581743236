import React, { useState } from 'react'
import Login from '../components/templates/Login'
import Checkout from '@cmp/templates/Checkout'
import { useMutation } from '@apollo/client'
import { useCartState, useCartActions } from '../context/Cart'
import { redirect } from '@src/utils'
import { CREATE_ORDER } from '../data/backend/queries'
import { useAuthentication } from '../context/Authentication'
import LoadingPage from '@cmp/templates/LoadingPage'

export default () => {
  const [anonymousCheckout, setAnonymousCheckout] = useState(false)
  const { user, loading: userLoading } = useAuthentication()
  const { cart, loading: cartLoading } = useCartState()
  const { changeQuantity } = useCartActions()

  const [createOrder, { data, error, loading: submitting }] = useMutation(
    CREATE_ORDER,
    {
      onCompleted: ({ createOrder: r }) => {
        if (r.__typename === 'Redirect') {
          const params = r.params.map(({ key, value }) => [key, value])
          redirect(r.url, params, { method: r.method })
        }
      }
    }
  )

  if (userLoading || cartLoading) {
    return <LoadingPage />
  }

  if (!user && !anonymousCheckout) {
    return <Login onSkipLogin={() => setAnonymousCheckout(true)} />
  }

  const onSubmit = form => {
    if (!submitting) {
      const shippingVariant = form.shippingVariant ? form.shippingVariant[form.shippingType] : undefined
      createOrder({
        variables: {
          input: {
            ...form,
            shippingVariant,
            token: cart.token,
            backToOnSuccess: location.origin + '/takk-fyrir?clear',
            backToOnCancel: location.origin
          }
        }
      })
      // send a purchase event to Google Analytics
      window.dataLayer = window.dataLayer || []
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'purchase', 
        ecommerce: {
          transaction_id: cart.token,
          value: cart.totalPrice,
          coupon: cart.promoCode || '',
          currency: 'ISK',
          shipping: shippingVariant ? shippingVariant.price : 0,
          tax: 0,
          items: cart.products.map((item, key) => ({
            item_id: item.id,
            item_name: item.product.title,
            discount: item.totalDiscount,
            index: key,
            item_category: item.productGroup.category.title,
            quantity: item.quantity,
            price: item.totalPrice
          }))
      }})
    }
  }

  const response = data && data.createOrder

  return (
    <Checkout
      cart={cart}
      onSubmit={onSubmit}
      submitting={submitting || response?.__typename === 'Redirect'}
      error={error || (response?.__typename === 'ErrorMessage' && response)}
      onChangeQuantity={changeQuantity}
    />
  )
}
